// Requirements
import React from 'react';
import AnimationContext from 'src/context/animationContext';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { SEO, Layout, Footer } from 'src/components/core';
import { graphql } from 'gatsby';

import { ModuleB, Back9 } from 'src/components/modules';
// Home Page Components

// "image": {
//       "src": "/products/insurance/individual-life-insurance.jpg",
//       "alt": "Individual Life Insurance"
//     },

import {
  HeaderSection,
  StayConnectedSection,
  MobileBankingSection,
  InvestSection,
  InsuranceSection,
} from 'src/components/pages/home-page';

import { FindLocation } from 'src/components/core';

// Import Page Styles
import './styles/index.scss';

const moduleData = {
  module: 'B',
  title: 'Life Insurance Simplified',
  subTitle: 'Our Quote and Apply tool allows you to:',
  content:
    '<ul><li>Instantly receive Life Insurance quotes from over 30 carriers</li><li>Complete an online application</li><li>Sign the agreement with DocuSign</li><li>Securely attain the policy from respective insurance carriers</li><li>**With or without a medical exam</li></ul><br/>We\'ll get your life insurance quotes in seconds, <a href="#b9">click here</a> to get started.',
  image: {
    src: '',
    alt: '',
  },
  items: [],
};

export default ({ data }) => {
  // const { language } = useI18next();
  // const pageData = data.allHomeJson.edges.filter(
  //   x => x.node.language === language
  // )[0].node;

  return (
    <Layout whiteNav>
      <SEO title="Lone Star National Bank" keywords="" description="" />
      <div className="home-page">
        <AnimationContext.Consumer>
          {value => <HeaderSection animDelay={value.animDelay} />}
        </AnimationContext.Consumer>
      </div>
    </Layout>
  );
};

// export const query = graphql`
//   query {
//     allHomeJson {
//       edges {
//         node {
//           header {
//             action {
//               openInNewWindow
//               title
//               url
//             }
//             quickLinks {
//               links {
//                 openInNewWindow
//                 title
//                 url
//               }
//               subTitle
//               title
//             }
//             subTitle
//             title
//           }
//           stayConnected {
//             action {
//               title
//               url
//             }
//             applePay {
//               description
//               title
//             }
//             cardManager {
//               description
//               title
//             }
//             title
//           }
//           services {
//             digital {
//               cardManager {
//                 target
//                 title
//               }
//               title
//               viewAll {
//                 target
//                 title
//               }
//             }
//             invest {
//               insurance {
//                 target
//                 title
//               }
//               investments {
//                 target
//                 title
//               }
//               savings {
//                 target
//                 title
//               }
//               title
//             }
//           }
//           mobileBanking {
//             apple
//             google
//             subTitle
//             title
//           }
//           page {
//             meta {
//               description
//               keywords
//             }
//             title
//           }
//           language
//         }
//       }
//     }
//   }
// `;

import Banking24Hour from 'src/assets/images/banking-product/24-Hour Phone Banking.png';
import AccountAnalysis from 'src/assets/images/banking-product/Account Analysis.png';
import AccountRecon from 'src/assets/images/banking-product/Account Reconciliation.png';
import Ach from 'src/assets/images/banking-product/ACH Origination.png';
import Allpoint from 'src/assets/images/banking-product/Allpoint ATM Network.png';
import ApplePay from 'src/assets/images/banking-product/ApplePay_GooglePay_SamsungPay.png';
import AtmDeposit from 'src/assets/images/banking-product/ATM Deposits.png';
import AtmCard from 'src/assets/images/banking-product/ATM or Visa® Debit Card.png';
import CdRom from 'src/assets/images/banking-product/CD-ROM Statement.png';
import EStatements from 'src/assets/images/banking-product/eStatements.png';
import InterestBearing from 'src/assets/images/banking-product/Interest Bearing.png';
import InternetBanking from 'src/assets/images/banking-product/Internet Banking.png';
import CardManager from 'src/assets/images/banking-product/LSNB Card Manager™.png';
import LsnbMobile from 'src/assets/images/banking-product/LSNB Mobile™.png';
import MerchantCardServices from 'src/assets/images/banking-product/Merchant Card Services.png';
import MinimumInitialDeposit from 'src/assets/images/banking-product/Minimum Initial Deposit.png';
import NightDepository from 'src/assets/images/banking-product/Night Depository Services.png';
import OfficeBanker from 'src/assets/images/banking-product/Office Banker™.png';
import PaperStatementFee from 'src/assets/images/banking-product/Paper Statement Fee.png';
import PaperStatements from 'src/assets/images/banking-product/Paper Statements.png';
import Person2Person from 'src/assets/images/banking-product/Person to Person Payments.png';
import PositivePay from 'src/assets/images/banking-product/Positive Pay.png';
import ServiceFee from 'src/assets/images/banking-product/Service Fee.png';
import SmsBanking from 'src/assets/images/banking-product/SMS Text Banking.png';
import StatementCycle from 'src/assets/images/banking-product/Statement Cycle.png';
import TransLimit from 'src/assets/images/banking-product/Transaction Limitations.png';
import UnlimitedBill from 'src/assets/images/banking-product/Unlimited Bill Pay.png';
import MobileDeposit from 'src/assets/images/banking-product/mobile-deposit.png';
import Bank2Bank from 'src/assets/images/banking-product/btb-transfers.png';

import BuyersProtection from 'src/assets/images/banking-product/buyers-protection-extended-warranty.png';
import CashiersChecks from 'src/assets/images/banking-product/cashier-checks-money-orders.png';
import Cellphone from 'src/assets/images/banking-product/cellphone-protection.png';
import EarnInterest from 'src/assets/images/banking-product/earn-interest.png';
// import EarnInterest from "src/assets/images/banking-product/earn-interest-on-your-balance.png"
import ExcessItem from 'src/assets/images/banking-product/excess-item-fee.png';
import HealthSavings from 'src/assets/images/banking-product/health-savings-card.png';
import LocalMerchantDiscounts from 'src/assets/images/banking-product/local-merchant-discounts.png';
import NotarySignature from 'src/assets/images/banking-product/notary-signature.png';
import PersonalIdentity from 'src/assets/images/banking-product/personal-identity-theft-restoration.png';
import RoadsideAssistance from 'src/assets/images/banking-product/roadside-assistance.png';
import DeathInsurance from 'src/assets/images/banking-product/10000-accidental-death-insurance.png';

export default {
  '24-Hour Phone Banking': Banking24Hour,
  'Account Analysis': AccountAnalysis,
  'Account Reconciliation': AccountRecon,
  'ACH Origination': Ach,
  'Allpoint ATM Network': Allpoint,
  'Apple Pay/Google Pay/Samsung Pay': ApplePay,
  'Apple Pay /Google Pay/Samsung Pay': ApplePay,
  'ATM Deposits & Withdrawals': AtmDeposit,
  'ATM Card': AtmCard,
  'ATM or Visa® Debit Card': AtmCard,
  'VISA® Business Debit Card': AtmCard,
  'CD-ROM Statement': CdRom,
  eStatements: EStatements,
  'Interest Bearing': InterestBearing,
  'Interest-Bearing': InterestBearing,
  'Internet Banking': InternetBanking,
  'Internet Banking Wire Transfers': InternetBanking,
  'LSNB Card Manager™': CardManager,
  'LSNB Mobile™': LsnbMobile,
  'Merchant Card Services': MerchantCardServices,
  'Minimum Initial Deposit': MinimumInitialDeposit,
  'Night Depository': NightDepository,
  'Night Depository Services': NightDepository,
  'Office Banker™': OfficeBanker,
  'Paper Statement Fee': PaperStatementFee,
  'Paper Statements': PaperStatements,
  'Paper Statement': PaperStatements,
  'Person to Person Payments': Person2Person,
  'Zelle®': Person2Person,
  'Positive Pay': PositivePay,
  'Service Fee': ServiceFee,
  'SMS Text Banking': SmsBanking,
  'Statement Cycle': StatementCycle,
  'Transaction Limitations': TransLimit,
  'Unlimited Bill Pay': UnlimitedBill,
  'LSNB QuickDeposit (Mobile Deposit).': MobileDeposit,
  'Bank to Bank Transfers': Bank2Bank,

  'Cell Phone Protection': Cellphone,
  'Roadside Assistance': RoadsideAssistance,
  'Buyers Protection & Extended Warranty': BuyersProtection,
  '$10,000 Accidental Death Insurance': DeathInsurance,
  'Local Merchant Discounts': LocalMerchantDiscounts,
  'Health Savings Card': HealthSavings,
  'Personal Identity Theft & Restoration': PersonalIdentity,
  'Cashier Checks or Money Orders': CashiersChecks,
  'Notary Signature Services': NotarySignature,
  'Earn Interest On Your Balance': EarnInterest,
  'Earn Interest* on Your Balance': EarnInterest,
  'Excess Item Fee': ExcessItem,
};

import React from 'react';
import './style.scss';

import SecureIcon from 'src/assets/images/contactless/secure.png';
import ConvenientIcon from 'src/assets/images/contactless/convenient.png';
import TouchFreeIcon from 'src/assets/images/contactless/touch-free.png';
import ContactlessImg from 'src/assets/images/contactless/contactless.png';
import BlueBG from 'src/assets/images/contactless/blue-bg.png';

import RetailIcon from 'src/assets/images/contactless/retail.png';
import GroceryIcon from 'src/assets/images/contactless/grocery.png';
import FastFoodIcon from 'src/assets/images/contactless/fast-food.png';
import ConvenienceIcon from 'src/assets/images/contactless/convenience-stores.png';
import DrugStoresIcon from 'src/assets/images/contactless/drug-stores.png';
import TransitIcon from 'src/assets/images/contactless/transit.png';
import VendingIcon from 'src/assets/images/contactless/vending-machines.png';
import MoreIcon from 'src/assets/images/contactless/more.png';

const iconListData = [
  {
    title: 'Secure',
    description:
      "Your LSNB Visa contactless chip card uses the same dynamic security as a contact chip card, so you know it's a secure way to pay!",
    icon: SecureIcon,
  },
  {
    title: 'Convenient',
    description: 'A simple tap is all it takes.',
    icon: ConvenientIcon,
  },
  {
    title: 'Touch-free',
    description:
      'Tapping to pay with your LSNB Visa contactless chip card can help you avoid touching surfaces at checkout where you see the Contactless Symbol.',
    icon: TouchFreeIcon,
  },
];

const columnData = [
  {
    items: [
      {
        title: 'Retail stores',
        icon: RetailIcon,
      },
      {
        title: 'Grocery stores',
        icon: GroceryIcon,
      },
      {
        title: 'Fast food restaurants',
        icon: FastFoodIcon,
      },
      {
        title: 'Convenience stores',
        icon: ConvenienceIcon,
      },
    ],
  },
  {
    items: [
      {
        title: 'Drug stores & retail pharmacies',
        icon: DrugStoresIcon,
      },
      {
        title: 'Trasit & Parking',
        icon: TransitIcon,
      },
      {
        title: 'Vending machines & unattended retail',
        icon: VendingIcon,
      },
      {
        title: 'More',
        icon: MoreIcon,
      },
    ],
  },
];

const IconBlock = ({ icon, title, description }) => {
  return (
    <div className="icon-block">
      <div className="icon">
        <img src={icon} alt={title} />
      </div>
      <div className="content">
        <h5>{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};

const SmallIconBlock = ({ icon, title }) => {
  return (
    <div className="small-icon-block">
      <div className="icon">
        <img src={icon} alt={title} />
      </div>
      <div className="content">
        <h5>{title}</h5>
      </div>
    </div>
  );
};

const ModuleJ = ({ data }) => {
  return (
    <section className="module-j">
      <div className="top">
        <div className="content-wrap">
          <h3>
            Tapping to pay with your LSNB Visa contactless chip is a secure,
            convenient and touch-free way to make everyday purchases. It gets
            you through contactless-enabled checkout quickly with a secure and
            touch-free way to pay.
          </h3>

          <div className="icon-list">
            {iconListData.map(data => (
              <IconBlock {...data} />
            ))}
          </div>
        </div>
        <div className="overlap-image">
          <img src={ContactlessImg} alt="Contactless Transactions" />
        </div>
      </div>
      <div className="bottom" style={{ backgroundImage: `url(${BlueBG})` }}>
        <div className="content-wrap">
          <h3>Use your LSNB Visa contactless chip card</h3>
          <h4>where you see the Contactless Symbol.</h4>
          <div className="columns">
            <div className="left-column">
              {columnData[0].items.map(item => (
                <SmallIconBlock {...item} />
              ))}
            </div>
            <div className="right-column">
              {columnData[1].items.map(item => (
                <SmallIconBlock {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModuleJ;

import React from 'react';
import gsap, { ScrollTrigger } from 'gsap/all';
import Slider from 'react-slick';

import { BlueArrowIcon } from 'src/components/icons';

import { Link } from 'src/components/core/external-link';
import { Title, TextBlock } from 'src/components/core';
import { Config } from 'src/util';

import AppleIcon from 'src/assets/images/home/apple-icon.png';
import CardManagerIcon from 'src/assets/images/home/card-manager-icon.png';
import AppleStore from 'src/assets/export/Home/images/PNG/image09.png';
import GoogleStore from 'src/assets/export/Home/images/PNG/image10.png';

import ApplePaySlide from 'src/assets/images/home/stay-connected-apple-pay-1.png';
import ApplePayAccent from 'src/assets/images/home/Phone.png';

import CardManagerSlide from 'src/assets/images/home/stay-connected-card-manager-1.png';
import CardManagerAccent from 'src/assets/images/home/Card.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class StayConnectedSliders extends React.Component {
  constructor(props) {
    super(props);

    gsap.registerPlugin(ScrollTrigger);

    this.data = props.data;
    this.state = {
      parent: null,
      child: null,
    };
  }

  componentDidMount() {
    this.setState({
      parent: this.textSlider,
      child: this.imageSlider,
    });
  }

  // showSlide(elem) {
  //   const g1 = asArray(elem.querySelectorAll(".text-slide__titleImage, .text-slide__subTitle, .text-slide__actions"))
  //   TweenMax.to(g1, 0.5, { autoAlpha: 1, y: 0, stagger: 0.125 })
  // }

  // hideSlide(elem) {
  //   this.textSlider
  //   const g1 = asArray(elem.querySelectorAll(".text-slide__titleImage, .text-slide__subTitle, .text-slide__actions"))
  //   const tl = new TimelineMax({
  //     onComplete: () => gsap.set(g1, { autoAlpha: 0, y: 20 })
  //   })
  //   tl.add(TweenMax.to(g1, 0.25, { autoAlpha: 0 }))
  //   tl.play()
  // }

  render() {
    const textSettings = {
      dots: true,
      arrows: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // beforeChange: this.hideSlide.bind(this)
      // autoplay: true,
      // autoplaySpeed: 5000,

      // afterChange: showCurrentSlide.bind(this),
      // fade: true,
    };

    const imageSettings = {
      variableWidth: true,
      infinite: false,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeable: false,
      autoplay: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            variableWidth: false,
            fade: true,
          },
        },
      ],
    };

    return (
      <>
        <div className="overflow"></div>
        <div className="text-slider">
          <Title>{this.data.title}</Title>
          <Slider
            ref={s => (this.textSlider = s)}
            asNavFor={this.state.child}
            {...textSettings}
          >
            <div className="text-slide">
              <img
                className="text-slide__titleImage"
                src={AppleIcon}
                alt={this.data.applePay.title}
              />
              <div className="text-slide__subTitle">
                {this.data.applePay.title}
              </div>
              <TextBlock>{this.data.applePay.description}</TextBlock>
              <div className="text-slide__actions">
                <Link
                  to={this.data.action.url}
                  className="link-button"
                  tabIndex="-1"
                >
                  <span>{this.data.action.title}</span>
                  <BlueArrowIcon />
                </Link>
              </div>
            </div>

            <div className="text-slide">
              <img
                className="text-slide__titleImage"
                src={CardManagerIcon}
                alt={this.data.cardManager.title}
              />
              <div className="text-slide__subTitle">
                {this.data.cardManager.title}
              </div>
              <TextBlock>{this.data.cardManager.description}</TextBlock>
              <div className="text-slide__actions">
                <Link
                  to={this.data.action.url}
                  className="link-button"
                  tabIndex="-1"
                >
                  <span>{this.data.action.title}</span>
                  <BlueArrowIcon />
                </Link>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '1.5rem',
                  }}
                >
                  <Link
                    to={Config.iTunes}
                    target="_blank"
                    title="Download LSNB App on the App Store"
                    className="image-button"
                    style={{ marginRight: '1rem' }}
                    tabIndex="-1"
                  >
                    <img
                      src={AppleStore}
                      alt="Download LSNB App on the App Store"
                    />
                  </Link>
                  <Link
                    to={Config.googlePlay}
                    target="_blank"
                    title="Download LSNB App on the Google Play Store"
                    className="image-button"
                    style={{ marginLeft: 0 }}
                    tabIndex="-1"
                  >
                    <img
                      src={GoogleStore}
                      alt="Download LSNB App on the Google Play Store"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="video-slider">
          <Slider
            ref={s => (this.imageSlider = s)}
            asNavFor={this.state.parent}
            {...imageSettings}
          >
            <div className="slide">
              <img
                className="accent"
                src={ApplePayAccent}
                alt={this.data.applePay.title}
              />
              <div className="slide-image">
                <img src={ApplePaySlide} alt={this.data.applePay.title} />
              </div>
            </div>

            <div className="slide">
              <img
                className="accent"
                src={CardManagerAccent}
                alt={this.data.cardManager.title}
              />
              <div className="slide-image">
                <img src={CardManagerSlide} alt={this.data.cardManager.title} />
              </div>
            </div>
          </Slider>
        </div>
      </>
    );
  }
}

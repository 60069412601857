import React, { useState, useEffect, useRef } from "react"
import gsap, { TimelineMax, TweenMax } from "gsap/all"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { CursorSpotlight } from "src/components/core"
import { HomeAddressIcon } from "src/components/icons"

import MapImage from "src/assets/export/Home/images/PNG/image13.png"

import "./style.scss"
import "./responsive.scss"

gsap.registerPlugin(ScrollTrigger)

export default () => {

  const sectionRef = useRef(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (sectionRef.current) {
      const leftEls = gsap.utils.toArray(sectionRef.current.querySelectorAll(`
        .effect-container,
        .stats-container
      `))

      const map = sectionRef.current.querySelector('.bg-layer img')
      const searchEls = gsap.utils.toArray(sectionRef.current.querySelectorAll(`
        .search-type,
        .search
      `))

      gsap.set(leftEls, { autoAlpha: 0, x: -30 })
      gsap.set(map, { autoAlpha: 0 })
      gsap.set(searchEls, { autoAlpha: 0, y: 30 })
    }

    const trigger = ScrollTrigger.create({
      trigger: "#find-location",
      start: "top 90%",
      onToggle: self => {
        if (self.isActive && !visible && sectionRef.current) {
          setTimeout(() => {
            const leftEls = gsap.utils.toArray(sectionRef.current.querySelectorAll(`
              .effect-container,
              .stats-container
            `))

            const map = sectionRef.current.querySelector('.bg-layer img')
            const searchEls = gsap.utils.toArray(sectionRef.current.querySelectorAll(`
              .search-type,
              .search
            `))

            const tl = new TimelineMax()
            tl.staggerTo(leftEls, 0.5, { autoAlpha: 1, x: 0 }, 0.25)
            tl.play()
            TweenMax.to(map, 1, { autoAlpha: 1 })

            setTimeout(() => {
              const tl2 = new TimelineMax()
              tl2.staggerTo(searchEls, 0.5, { autoAlpha: 1, y: 0 })
              tl2.play()
            }, 300)

            setVisible(true)
          }, 100)

        }
      }
    })

    return () => {

    }
  }, [visible, setVisible])

  return (
    <section id="find-location" className="find-location" ref={sectionRef}>

      <div className="wrapper">
        <div className="bg-layer">
          <img src={MapImage} alt="Rio Grande Valley" />
        </div>

        <div className="fg-layer">
          <div className="numbers-and-stats">
            <div className="effect-container">
              <CursorSpotlight>
                <h1>
                  Find a Location <br />
                  Near You
                </h1>
              </CursorSpotlight>
            </div>
            <div className="stats-container">
              <div className="stat">
                <p className="number">100</p>
                <p className="label">Locations</p>
              </div>
              <div className="stat">
                <p className="number">1,123</p>
                <p className="label">Lorem Ipsum</p>
              </div>
              <div className="stat">
                <p className="number">1,123</p>
                <p className="label">Community Outreach</p>
              </div>
            </div>
          </div>
          <div className="search-container">
            <div className="search-type">
              <a href="#" className="active" data-search-type="banking">Banking</a>
              <a href="#" data-search-type="atm">ATMs</a>
            </div>
            <div class="search">
              <form>
                <input id="search-type" name="search-type" type="hidden" value="banking" />
                <div class="search-input-wrapper">
                  <HomeAddressIcon />
                  <input id="query" name="query" type="text" placeholder="Enter Address or Zip Code" />
                  <button>
                    {`>`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


    </section>
  )
}

import React, { useEffect, useRef } from 'react';
import gsap, { TimelineMax, TweenMax } from 'gsap/all';
import { Accordion, AccordionItem } from 'react-sanfona';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { RoundedImage } from 'src/components/core';
import { asArray } from 'src/util';

import Icon1 from 'src/assets/images/contactless/icon1.png';
import Icon2 from 'src/assets/images/contactless/icon2.png';
import Icon3 from 'src/assets/images/contactless/icon3.png';
import Icon4 from 'src/assets/images/contactless/icon4.png';
import Icon5 from 'src/assets/images/contactless/icon5.png';
import AccentImg from 'src/assets/images/contactless/CardAccent.png';

import './style.scss';

const globalTranslations = {
  en: require('../../../../locales/en/global.json'),
  es: require('../../../../locales/es/global.json'),
};

const accordionData = [
  {
    icon: Icon1,
    title: 'How do I know if I can tap to pay with my card?',
    subTitle:
      'Look for the Contactless Indicator on your card to know if you can use it to tap to pay for  purchases.',
  },
  {
    icon: Icon2,
    title:
      'Do I actually have to tap my contactless chip card to the contactless-enabled terminal?',
    subTitle:
      'Look for the Contactless Indicator on your card to know if you can use it to tap to pay for  purchases.',
  },
  {
    icon: Icon3,
    title:
      'How do I hold my contactless chip card to the contactless- enabled terminal?',
    subTitle:
      'Look for the Contactless Indicator on your card to know if you can use it to tap to pay for  purchases.',
  },
  {
    icon: Icon4,
    title:
      'How long do I need to hold my contactless chip card to the contactless-enabled temrinal?',
    subTitle:
      'Look for the Contactless Indicator on your card to know if you can use it to tap to pay for  purchases.',
  },
  {
    icon: Icon5,
    title:
      'Can I unknowigly make a purchase if I am in close proximity to  a contactless-enabled terminal?',
    subTitle:
      'Look for the Contactless Indicator on your card to know if you can use it to tap to pay for  purchases.',
  },
];

export default ({ sectionId }) => {
  const { language } = useI18next();
  const t = globalTranslations[language];
  const elem = useRef(null);
  const revealTween = useRef(null);

  useEffect(() => {
    const g1 = elem.current.querySelector('.content-wrap');
    const g2 = asArray(
      elem.current.querySelectorAll(
        '.accordion .title h5, .accordion .title h3, .accordion .title p'
      )
    );
    const g3 = asArray(elem.current.querySelectorAll('.react-sanfona-item'));
    const g4 = asArray(
      elem.current.querySelectorAll('.action-bar, .video-container')
    );

    gsap.set(g1, { autoAlpha: 0 });
    gsap.set(g2, { autoAlpha: 0, y: 20 });
    gsap.set(g3, { autoAlpha: 0, x: -20 });
    gsap.set(g4, { autoAlpha: 0, x: 20 });

    revealTween.current = new TimelineMax({
      scrollTrigger: elem.current,
      start: 'top 80%',
    });

    revealTween.current
      .add(TweenMax.to(g1, 0.5, { autoAlpha: 1 }))
      .add(
        TweenMax.to(g2, 0.5, { autoAlpha: 1, y: 0, stagger: 0.15 }),
        '-=0.25'
      )
      .add(TweenMax.to(g3, 0.5, { autoAlpha: 1, x: 0, stagger: 0.15 }), '-=0.4')
      .add(
        TweenMax.to(g4, 0.5, { autoAlpha: 1, x: 0, stagger: 0.15 }),
        '-=0.5'
      );

    return () => revealTween.current.kill();
  });

  const AccordionTitle = ({ title, icon, onClick }) => {
    return (
      <div className="react-sanfona-item-title" onClick={onClick}>
        <div className="icon">
          <img src={icon} alt={title} />
        </div>

        <span>{title}</span>
      </div>
    );
  };

  return (
    <section id={sectionId} className="section-k-accordion" ref={elem}>
      <div className="content-wrap">
        <div className="imagery">
          <div className="video-container">
            <RoundedImage
              src={AccentImg}
              alt="Want to know more? Here are som frequently questions."
            />
          </div>
        </div>

        <div className="accordion">
          <div className="title">
            <h3>Want to know more? Here are some frequently questions.</h3>
          </div>

          <Accordion atomic={true}>
            {accordionData.map((product, index) => (
              <AccordionItem
                title={<AccordionTitle {...product} />}
                expanded={index === 0}
              >
                <p>{product.subTitle}</p>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

import React, { useEffect, useRef } from 'react';
import { TimelineMax } from 'gsap/all';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link } from 'src/components/core/external-link';
import { Button } from 'src/components/core';
import { RightChevronIcon } from 'src/components/icons';

import HeaderImg1 from 'src/assets/images/lsnb_rocket.jpg';
import HeaderAccent1 from 'src/assets/images/home/contactless-header-accent.png';
import HeaderAccent2 from 'src/assets/images/home/contactless-header-accent-2.png';

import './new-style.scss';
import { Logo } from '../../../icons/navigation';

export default ({ data }) => {
  const { t } = useTranslation('LoginModal');

  return (
    <header className="home-page-header">
      <Logo />
      <h1>
        Pardon the interruption, but this page is under construction and will be
        back up shortly
      </h1>
    </header>
  );

  // return (
  //   <ImagesLoaded onAlways={() => setImagesLoaded(true)} background={true}>
  //     <header className="home-page-header" ref={elem}>
  //       <div className="gradient-animation"></div>
  //       <div className="content">
  //         <div className="text-content">
  //           <div className="text-content-wrapper">
  //             <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
  //             <h2 dangerouslySetInnerHTML={{ __html: data.subTitle }}></h2>
  //             <Button
  //               to={data.action.url}
  //               openInNewWindow={data.action.openInNewWindow}
  //               trackCategory="Accounts"
  //               trackEvent="OpenAccount"
  //               trackLabel="Account Conversions"
  //             >
  //               {data.action.title}
  //             </Button>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="quick-links">
  //         <div className="preface">
  //           <h3>{data.quickLinks.title}</h3>
  //           <p>{data.quickLinks.subTitle}</p>
  //         </div>
  //         {data.quickLinks.links.map((link, index) => (
  //           <QuickLink
  //             title={link.title}
  //             url={link.url}
  //             openInNewWindow={link.openInNewWindow}
  //             icon={iconTypes[index]}
  //           />
  //         ))}
  //       </div>
  //     </header>
  //   </ImagesLoaded>
  // );
};

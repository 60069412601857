// Requirements
import React, { useEffect, useRef } from "react"
import spirit from "spiritjs"
import { Link } from "gatsby-plugin-react-i18next"
import gsap, { TimelineMax, TweenMax } from "gsap/all"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import { asArray } from "src/util"

import { HomeInsuranceIcon, HomeLendingIcon, HomeInvestmentsIcon, BlueArrowIcon } from "src/components/icons"

// import { ReactComponent as InvestSvg } from "src/assets/svg/home/invest-animation.svg"
import InvestSvg from "src/components/anims/invest"
import LinksBlockBG from "src/assets/images/home/invest-2-bg.png"
import LinksBlock from "src/assets/images/home/invest-2.png"

// Styles
import "./style.scss"
import "./responsive.scss"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(DrawSVGPlugin)

export default ({ data }) => {

  const elem = useRef(null)
  const investAnim = useRef(null)
  const tween = useRef(null)

  useEffect(() => {
    // Lookup Elements
    const invest = elem.current.querySelector(".invest-block")
    const investChildren = invest.querySelectorAll("h4, .icon-link")
    const db = elem.current.querySelector(".links-block")
    const dbChildren = db.querySelectorAll("h4, .icon-link")

    gsap.set(invest, { autoAlpha: 0, x: -30 })
    gsap.set(asArray(investChildren), { autoAlpha: 0, y: 20 })
    gsap.set(db, { autoAlpha: 0, x: 30 })
    gsap.set(asArray(dbChildren), { autoAlpha: 0, y: 20 })

    tween.current = new TimelineMax({
      scrollTrigger: {
        trigger: elem.current,
        start: "top 90%"
      }
    })

    let investTl = null
    spirit.setup(gsap)
      .then(() => {
        spirit.loadAnimation({
          path: "/animations/Invest.json",
          loop: false,
          autoPlay: false,
          container: investAnim.current
        }).then(tl => {
          investTl = tl
        })
      })


    tween.current
      .add(
        (new TimelineMax())
          .add(TweenMax.to(invest, 0.5, { autoAlpha: 1, x: 0 }))
          .add(TweenMax.to(asArray(investChildren), 0.5, { autoAlpha: 1, y: 0, stagger: 0.09 }))
          .add(() => {
            if (investTl !== null)
              investTl.play()  
          })    
      )
      .add(
        (new TimelineMax())
          .add(TweenMax.to(db, 0.5, { autoAlpha: 1, x: 0 }))
          .add(TweenMax.to(asArray(dbChildren), 0.5, { autoAlpha: 1, y: 0, stagger: 0.09 })), "-=1"
      )
    return () => tween.current.kill()
  })



  return (
    <section id="invest" className="invest" ref={elem}>
      <div className="content-wrap">
        <div className="invest-block">
          <h4>{data.invest.title}</h4>
          <div className="links">
            <Link to={data.invest.insurance.target} className="icon-link">
              <HomeInsuranceIcon />
              <span>{data.invest.insurance.title}</span>
              <BlueArrowIcon />
            </Link>
            
            <Link to={data.invest.investments.target} className="icon-link">
              <HomeInvestmentsIcon />
              <span>{data.invest.investments.title}</span>
              <BlueArrowIcon />
            </Link>

            <Link to={data.invest.savings.target} className="icon-link">
              <HomeLendingIcon />
              <span>{data.invest.savings.title}</span>
              <BlueArrowIcon />
            </Link>
          </div>

          <div className="animation" ref={investAnim}>
            <InvestSvg />
          </div>
        </div>
        <div className="links-block" style={{"backgroundImage": LinksBlockBG}}>
          <h4>{data.digital.title}</h4>
          <div className="links">
            <Link to={data.digital.cardManager.target} className="icon-link">
              <HomeInsuranceIcon />
              <span>{data.digital.cardManager.title}</span>
              <BlueArrowIcon />
            </Link>
            <Link to={data.digital.viewAll.target} className="icon-link">
              <HomeInvestmentsIcon />
              <span>{data.digital.viewAll.title}</span>
              <BlueArrowIcon />
            </Link>
          </div>
          <div className="animation">
            <img src={LinksBlock} alt="Investments" />
          </div>
        </div>
      </div>
    </section>
  )
}
